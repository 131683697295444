<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #heading>
        <SecurityLogo />
      </template>

      <template #card>
        <div class="">
          <div class="text-center">
            <h1> Using Authentication App </h1>
          </div>
          <div class=" pt-1">
            <p class="m-0 p-0">
              1. You need to install an authenticator app
            </p>
            <p class="m-0 p-0">
              2. Scan the QR code below with your authenticator app.
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">

          <!--                 this QR Code that will go when APIs are connected-->
          <img :src="qr_code" alt="authentication_qr_code">
        </div>
        <div class="pt-1">
          <validation-observer ref="authenticateForm" #default="{invalid}">
            <b-form @submit.prevent="verify">
              <!--                username -->
              <b-form-group label-for="code">
                <validation-provider
                    #default="{ errors }"
                    name="Code"
                    vid="code"
                    rules="required"
                >
                  <div class="exxtra-input">

                    <input id="login-username" v-model="code" type="number" class="otp"
                           placeholder="* * * * *"
                    >
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div>
                <b-button
                    class="py-1"
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                >
                  Continue
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>
import SecurityLogo from "@/pages/auth/shared-components/SecurityLogo.vue";
import { required } from "@core/utils/validations/validations";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow
} from "bootstrap-vue";
import { get } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    SecurityLogo,
    AuthWrapper,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      authentication_method: '',
      loading: false,
      code: '',
      qr_code: '',
      secret: '',
      // validation rules
      required
    }
  },
  computed: {

  },
  created() {
    this.retrieveQRCode();
  },
  methods: {
    async verify() {
      this.loading = true;
      try {
        const payload = {
          mfa_method: "app",
          token: this.code
        }
        const response = await this.useJwt()
            .mfaService
            .verifyAndActivate(payload);

        const response_data = response.data.data;

        this.$router.push({
          name: "security-mfa-recovery-codes",
          params: { recovery_codes: response_data.recovery_codes }
        })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'MFA Activation Successful',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Kindly store the available recovery codes'
                },
              });
            })
            .catch(() => {
            });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Mfa Activation Failed',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async retrieveQRCode() {
      const { authentication_data } = this.$route.params
      if (authentication_data) {
        this.qr_code = authentication_data.qr_data_url;
        this.secret = authentication_data.secret;
      } else {
        await this.resendMfaEnrollment();
      }
    },
    async resendMfaEnrollment() {
      this.loading = true;
      try {
        const payload = {
          mfa_method: "app"
        }
        const response = await this.useJwt()
            .mfaService
            .resendMfaOtp(payload);
        const response_data = response.data.data;
        this.qr_code = response_data.qr_data_url;
        this.secret = response_data.secret;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = true;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
